import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ColorModeContext } from "./ColorModeContext";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

function HeaderBar() {
  const { colorMode, setColorMode } = useContext(ColorModeContext);

  return (
    <AppBar position="static" enableColorOnDark>
      <Toolbar>
        <IconButton>
          <img
            src="/favicon.ico"
            width="32px"
            height="32px"
            alt="A book icon colored like a mythic rare set symbol."
          />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Home Room
        </Typography>
        <IconButton
          onClick={() => {
            setColorMode(colorMode === "light" ? "dark" : "light");
          }}
          color="inherit"
        >
          {colorMode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default HeaderBar;
