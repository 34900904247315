import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import FlashCardSetModel from "./FlashCardSetModel";
import FlashCard from "./FlashCard";

/**
 * A view that allows the user to select which flash card to look at.
 */
function FlashCardSetNavigator({ dataUrl }: { dataUrl: string }) {
  const [page, setPage] = useState(1);
  const [data, setData] = useState<FlashCardSetModel>();
  const [currentDataUrl, setCurrentDataUrl] = useState("");
  const [autoHideAnswers, setAutoHideAnswers] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(currentDataUrl, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (currentDataUrl !== "") {
      fetchData();
    }
  }, [currentDataUrl]);

  useEffect(() => {
    if (dataUrl !== currentDataUrl) {
      setCurrentDataUrl(dataUrl);
    }
  }, [dataUrl, currentDataUrl]);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
        event.preventDefault();
        if (event.key === "ArrowRight") {
          advancePage(1);
        } else {
          advancePage(-1);
        }
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  });

  const curPage = () => {
    // This handles the case where the data has changed so that page is now
    // out of range.
    if (data) {
      return page > data.cards.length ? data.cards.length : page;
    } else {
      return 1;
    }
  };

  const advancePage = (pages: number) => {
    if (data) {
      const newPage =
        1 + ((curPage() - 1 + pages + data.cards.length) % data.cards.length);
      setPage(newPage);
    }
  };

  const randomNumberInRange = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  function AdvanceCardButtonGroup() {
    return (
      <ButtonGroup
        orientation="vertical"
        variant="text"
        sx={{ justifyContent: "center", pointerEvents: "auto" }}
      >
        <IconButton
          color="primary"
          aria-label="previous card"
          onClick={() => {
            advancePage(-1);
          }}
        >
          <Icon>arrow_back</Icon>
        </IconButton>
        <IconButton
          color="primary"
          aria-label="random card"
          onClick={() => {
            // We do not want to stay on the same page, so we make sure the number of
            // pages we advance is in [1, number of cards - 1].
            if (data) {
              advancePage(randomNumberInRange(1, data.cards.length - 1));
            }
          }}
        >
          <Icon>casino</Icon>
        </IconButton>
        <IconButton
          color="primary"
          aria-label="next card"
          onClick={() => {
            advancePage(1);
          }}
        >
          <Icon>arrow_forward</Icon>
        </IconButton>
      </ButtonGroup>
    );
  }

  if (currentDataUrl !== "") {
    return (
      <div>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <Stack alignItems="center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoHideAnswers}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAutoHideAnswers(event.target.checked);
                  }}
                />
              }
              label="Auto-hide answers"
            />
          </FormGroup>
          <Stack
            alignItems="center"
            spacing={1}
            sx={{
              pt: 1,
              pb: 1,
              width: "100%",
              position: "relative",
            }}
          >
            <Box sx={{ width: "100%", position: "relative" }}>
              {data ? (
                <FlashCard
                  model={data.cards[curPage() - 1]}
                  autoHideAnswers={autoHideAnswers}
                />
              ) : null}
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  top: "0px",
                  height: "320px",
                  width: "100%",
                  position: "absolute",
                  pointerEvents: "none",
                }}
              >
                <AdvanceCardButtonGroup />
                <AdvanceCardButtonGroup />
              </Stack>
            </Box>
            {data ? (
              <Pagination
                count={data.cards.length}
                page={curPage()}
                onChange={(event, value) => {
                  setPage(value);
                }}
                hidePrevButton
                hideNextButton
              />
            ) : null}
          </Stack>
        </Stack>
      </div>
    );
  } else {
    return <div />;
  }
}

export default FlashCardSetNavigator;
