import { PaletteMode } from "@mui/material";

export function getDesignTokens(mode: PaletteMode) {
  // Based on https://ethanschoonover.com/solarized/
  const base03 = "#002b36";
  const base02 = "#073642";
  const base01 = "#586e75";
  const base00 = "#657b83";
  const base0 = "#839496";
  const base1 = "#93a1a1";
  const base2 = "#eee8d5";
  const base3 = "#fdf6e3";

  //const yellow = "#b58900";
  //const orange = "#cb4b16";
  //const red = "#dc322f";
  const magenta01 = "#B50769";
  const magenta = "#d33682";
  const magenta1 = "#F1559C";
  //const violet = "#6c71c4";
  //const blue = "#268bd2";
  const cyan01 = "#00877E";
  const cyan = "#2aa198";
  const cyan1 = "#4DBDB3";
  //const green = "#859900";

  return {
    palette: {
      mode,
      ...(mode === "light"
        ? {
            // palette values for light mode
            primary: {
              light: cyan1,
              main: cyan,
              dark: cyan01,
              contrast_text: base01,
            },
            secondary: {
              light: magenta1,
              main: magenta,
              dark: magenta01,
              contrast_text: base01,
            },
            background: {
              default: base3,
              paper: base2,
            },
            text: {
              primary: base00,
              secondary: base01,
            },
          }
        : {
            // palette values for dark mode
            primary: {
              light: cyan1,
              main: cyan,
              dark: cyan01,
              contrast_text: base1,
            },
            secondary: {
              light: magenta1,
              main: magenta,
              dark: magenta01,
              contrast_text: base1,
            },
            background: {
              default: base03,
              paper: base02,
            },
            text: {
              primary: base0,
              secondary: base01,
            },
          }),
    },
  };
}
