import React from "react";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function Disclaimers() {
  return (
    <Stack spacing={2}>
      <Typography variant="body2">
        MtG Home Room is unofficial Fan Content permitted under the{" "}
        <Link href="https://company.wizards.com/en/legal/fancontentpolicy">
          Fan Content Policy
        </Link>
        . Not approved or endorsed by Wizards. Portions of the materials used
        are property of Wizards of the Coast. ©Wizards of the Coast LLC.
      </Typography>
      <Typography variant="body2">
        Portions of this site are based on Scryfall data, used in accordance
        with their <Link href="https://scryfall.com/docs/api">API</Link>{" "}
        guidelines. This site is not endorsed by Scryfall, but we certainly
        endorse them! Please consider supporting Scryfall{" "}
        <Link href="https://scryfall.com/donate">directly</Link> or by becoming
        a <Link href="https://www.patreon.com/scryfall">patron</Link>.
      </Typography>
    </Stack>
  );
}

export default Disclaimers;
