import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FlashCardSetNavigator from "./FlashCardSetNavigator";

function FlashCardSetSelector() {
  interface StringByString {
    [key: string]: string;
  }

  const flashcardSets: StringByString = {
    "Set symbols, main sets": "flashcard_data/set_symbols_basics.json",
    "Set symbols, other paper sets":
      "flashcard_data/set_symbols_everything_else.json",
    "Sets by year, main sets": "flashcard_data/sets_by_year_main_sets.json",
  };
  const [selectedSet, setSelectedSet] = useState(Object.keys(flashcardSets)[0]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedSet(event.target.value as string);
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="set-selection-label">Flash Card Set</InputLabel>
        <Select
          id="set-selection"
          labelId="set-selection-label"
          label="Flash Card Set"
          value={selectedSet}
          onChange={handleChange}
        >
          {Object.keys(flashcardSets).map((setName) => (
            <MenuItem key={setName} value={setName}>
              {setName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FlashCardSetNavigator dataUrl={flashcardSets[selectedSet]} />
    </div>
  );
}

export default FlashCardSetSelector;
