import { createContext, Dispatch, SetStateAction } from "react";
import { PaletteMode } from "@mui/material";

interface ColorModeContextType {
  colorMode: PaletteMode;
  setColorMode: Dispatch<SetStateAction<PaletteMode>>;
}

export const ColorModeContext = createContext<ColorModeContextType>({
  colorMode: "light",
  setColorMode: () => {},
});
